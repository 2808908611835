import { lightDarkBlue } from 'components/baseColor'
import styled from 'styled-components'
import { IS_FRAME_MOBILE, IS_FRAME_TABLET } from 'utils'

export const Wrapper = styled.div`
  margin: 1rem 8rem;
  font-family: "Open Sans", system-ui, -apple-system, "Segoe UI", Roboto, Helvetica, Arial,
  sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  color: ${lightDarkBlue};
  min-height: 75vh;

   h2 {
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.25px;
    font-size: 1.4rem;
  }

  ${IS_FRAME_MOBILE} {
    margin: 1rem 1rem 2rem;

    > h2 {
      font-size: 1.1rem;
    }
  }

  ${IS_FRAME_TABLET} {
    margin: 1rem 6rem;
  }
`

export const SummaryWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    text-align: right;
  }

  ${IS_FRAME_MOBILE} {
    margin-top: 1rem;
    align-items: baseline;

    > h2 {
        margin: 0;
    }
  }

  ${IS_FRAME_TABLET} {
    align-items: baseline;
  }
`

export const TotalBalance = styled.div`

  > div {
    font-size: 0.95rem;
  }

  > h3 {
    letter-spacing: 0.25px;
    font-weight: 500;
    margin-bottom: 0;
    font-size: 1.25rem;
    margin-top: 0.4rem;
  }

  > h4 {
    letter-spacing: 0.25px;
    font-weight: 500;
    font-size: 1.1rem;
    margin-top: 0.25rem;
  }

  ${IS_FRAME_MOBILE} {

    > h3 {
      margin-top: 0.5rem;
      font-size: 1.1rem;
      letter-spacing: 0.25px;
    }

    > h4 {
      margin-top: 0.5rem;
      font-size: 0.9rem;
      letter-spacing: 0.25px;
    }
  }
`

export const Grid = styled.div`
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: 1fr 1fr 1fr;
  font-family: "Open Sans", system-ui, -apple-system, "Segoe UI", Roboto, Helvetica, Arial,
  sans-serif, "Apple Color Emoji", "Segoe UI Emoji";

  ${IS_FRAME_MOBILE} {
    margin: 0 0 2rem;
    display: block;
  }

  ${IS_FRAME_TABLET} {
    display: block;
  }
`

export const FilterWrapper = styled.div`
  display: flex;
  margin-bottom: 1.5rem;

  > div {
    margin-right: 1rem;
    display: flex;

    > span {
      line-height: 1.43;
      letter-spacing: 0.25px;
      font-size: 0.875rem;
    }
  }

  ${IS_FRAME_MOBILE} {
   margin-bottom: 0.5rem;
  }
`
