import { AffiliateDashboardLayout } from 'components/AffiliateDashboard'
import PayoutsView from 'domains/AffiliateDashboard/Payouts'
import React from 'react'
import withAuth from '../../hocs/with-auth'

const redirectUrl = '/affiliate-dashboard/login?redirectUrl=/affiliate-dashboard/payouts'

const Payouts = ({ location }) => (
  <AffiliateDashboardLayout>
    <PayoutsView location={ location }/>
  </AffiliateDashboardLayout>
)

export default withAuth(Payouts, redirectUrl)
