import React from 'react'
import dayjs from 'dayjs'
import { 
  IconCalender, 
  IconProcess, 
  IconSuccess 
} from '@qlue/icon-kit'
import {
  Card,
  Flex,
  PayoutDate,
  PayoutStatus,
  ClientName,
  CommissionWrapper,
  IdNumber
} from './styles'
import { formatMoney } from 'utils'
import PropTypes from 'prop-types'

const propTypes = {
  payout: PropTypes.shape({
    id          : PropTypes.string,
    amount      : PropTypes.number,
    transaction : PropTypes.shape({
      account: PropTypes.shape({
        company_name: PropTypes.string
      })
    }),
    affiliate_payout: PropTypes.shape({
      transfer_date           : PropTypes.string,
      affiliate_payout_status : PropTypes.shape({
        name: PropTypes.string
      })
    }),
    currency: PropTypes.shape({
      id   : PropTypes.string,
      name : PropTypes.string
    })
  })
}

const defaultProps = {
  payout: PropTypes.shape({
    id          : '',
    amount      : 0,
    transaction : PropTypes.shape({
      account: PropTypes.shape({
        company_name: ''
      })
    }),
    affiliate_payout: PropTypes.shape({
      transfer_date           : '',
      affiliate_payout_status : PropTypes.shape({
        name: ''
      })
    }),
    currency: PropTypes.shape({
      id   : '',
      name : 'IDR'
    })
  })
}

const PAYOUT_STATUS = {
  PAID        : 'Paid',
  ON_PROGRESS : 'On Progress'
}

const PayoutCard = ({ payout }) => (
  <Card>
    <Flex>
      <div>
        <div>
          ID
        </div>
        <IdNumber>
          { payout.affiliate_payout.payout_no }
        </IdNumber>
      </div>

      <div>
        <PayoutStatus isSuccess={ payout.affiliate_payout.affiliate_payout_status.name === PAYOUT_STATUS.PAID }>
          { payout.affiliate_payout.affiliate_payout_status.name }
        </PayoutStatus>
        { 
          payout.affiliate_payout.affiliate_payout_status.name === PAYOUT_STATUS.PAID 
            ? <IconSuccess width='20' height='20'/> 
            : <IconProcess width='20' height='20'/> 
        }
      </div>
    </Flex>

    <PayoutDate>
      <IconCalender />
      <p>
        { dayjs(payout.affiliate_payout.transfer_date).format('DD MMMM YYYY') }
      </p>
    </PayoutDate>

    <ClientName>
      { payout.transaction?.account.company_name || '-' }
    </ClientName>

    <CommissionWrapper>
      <div>
        Commision
      </div>
      <h3>
        { formatMoney(payout.amount , { currency: payout.currency?.name || 'IDR' }) }
      </h3>
    </CommissionWrapper>
  </Card>
)

PayoutCard.propTypes = propTypes
PayoutCard.defaultProps = defaultProps

export default PayoutCard