import { lightDarkBlue, isGreen, moonYellow } from 'components/baseColor'
import styled from 'styled-components'
import { IS_FRAME_MOBILE, IS_FRAME_TABLET } from 'utils'

export const Card = styled.div`
  border: 1px solid #dee2ee;
  border-radius: 0.5rem;
  padding: 1rem;
  color: ${lightDarkBlue};

  ${IS_FRAME_MOBILE} {
    padding: 1rem;
    margin: 1.25rem 0;
  }

  ${IS_FRAME_TABLET} {
    margin-bottom: 1rem;
  }
`

export const Flex = styled.div`
  display: flex;
  margin: 0.5rem 0;
  justify-content: space-between;
  align-items: center;

  > div {
    &:first-child {
      flex: 0 0 60%;
      display: flex;
      align-items: center;
      > div {
        margin-right: 0.5rem;
      }
    }

    &:last-child {
      display: flex;
      align-items: center;
    }
  }

  ${IS_FRAME_MOBILE} {
    margin-top: 0;

    &:last-child {
      display: flex;
      align-items: center;

       svg {
        width: 3rem;
        height: 3rem;
      }
    }
  }
`

export const PayoutDate = styled.div`
  white-space: nowrap;
  display: flex;
  font-size: 0.875rem;
  letter-spacing: 0.25px;
  align-items: center;

  > svg {
    margin-right: 1rem;
  }

  p {
    margin: 0;
    letter-spacing: 0.25px;
    font-weight: 500;
    font-size: 0.9rem;
  }

  ${IS_FRAME_MOBILE} {
    p {
      margin: 0;
      font-weight: bolder;
    }
  }
`

export const PayoutStatus = styled.div`
  color: ${({ isSuccess }) => isSuccess ? isGreen : moonYellow };
  margin-right: 0.5rem;
  line-height: 1.43;
  letter-spacing: 0.25px;
  font-weight: 500;

  ${IS_FRAME_MOBILE} {
    font-size: 0.8rem;
  }
`

export const ClientName = styled.h3`
  font-weight: 600;
  font-size: 1.1rem;
  word-break: break-all;

  ${IS_FRAME_MOBILE} {
    font-size: 0.975rem;
    margin: 0.75rem 0;
    font-weight: 600;
  }
`

export const CommissionWrapper = styled.div`

  > div {
    letter-spacing: 0.25px;
    font-size: 0.875rem;
  }

  > h3 {
    margin-top: 0.5rem;
    font-weight: 500;
    font-size: 1.25rem;
  }

  ${IS_FRAME_MOBILE} {
    > h3 {
      font-size: 1.2rem;
      font-weight: bolder;
    }
  }
`

export const IdNumber = styled.div`
  font-weight: 600;
  font-size: 0.9rem;
`