import React, { 
  useEffect, 
  useState, 
  useCallback
} from 'react'
import { CheckBox, Shimmer } from 'components'
import { 
  Breadcrumbs, 
  EmptyState,
  AffiliateDashButton
}from 'components/AffiliateDashboard'
import { PayoutCard } from './components'
import {
  Wrapper,
  SummaryWrapper,
  Grid,
  FilterWrapper
} from './styles'
import { AffiliatePayoutsURL } from 'commons/API'
import { useAuth } from '../../../providers/auth-provider'
import { useToasts } from 'react-toast-notifications'
import AffiliateAPI from 'commons/API/affiliate'
import { Link } from 'gatsby'

const AFFILIATE_STATUS = {
  ACTIVE  : 'Active',
  PENDING : 'Request to Join'
}

const PayoutsView = ({ location }) => {

  const { user } = useAuth()
  const { addToast } = useToasts()

  const [payoutList, setPayoutList] = useState([])
  const [filter, setFilter] = useState({ affiliate_payout_status_id: [] })
  const [statusOptions, setStatusOptions] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [checked, setChecked] = useState({})
  
  const fetchPayouts = useCallback(async(filter) => {
    setIsLoading(true)
    await AffiliateAPI.post(AffiliatePayoutsURL.PostPayoutList, { filter })
      .then((response) => {
        const data = response.data.affiliate_balances
        setPayoutList(data || [])
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        if(error.response.status !== 403){
          addToast(error.response.data.message, { appearance: 'warning' })
        }
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchStatusOptions = useCallback(async () => {
    await AffiliateAPI.get(AffiliatePayoutsURL.GetPayoutStatusFilter)
      .then((response) => {
        const data = response.data.affiliate_payout_statuses
        const exceptAllOptions = data.reverse().filter((status) => status.id !== 'all')
        exceptAllOptions.map((status) => {
          const name = status.name

          setChecked((prevValue) => ({
            ...prevValue,
            [name]: false
          }))
          return null
        })
        setStatusOptions(exceptAllOptions)
      })
  }, [])

  useEffect(() => {
    fetchPayouts(filter)
  }, [fetchPayouts, filter])

  useEffect(() => {
    fetchStatusOptions()
  }, [fetchStatusOptions])

  const PayoutLisCard = () => {

    if(!payoutList.length || user.affiliate_status?.name === AFFILIATE_STATUS.PENDING) return (
      <EmptyState text={ 'Sorry, You don\'t have payout history yet.' }/>
    )

    return(
      <>
        <Grid>
          {
            payoutList.map((payout, i) => (
              <PayoutCard 
                key={ `payout-${i}` }
                payout={ payout }
              />
            ))
          }
        </Grid>
      </>
    )
  }

  return(
    <Wrapper>
      <Breadcrumbs location={ location } />

      <SummaryWrapper>
        <h2>
          Payouts
        </h2>

        <Link to='/affiliate-dashboard/transactions'>
          <AffiliateDashButton type='primary'>Transaction</AffiliateDashButton>
        </Link>

      </SummaryWrapper>
      {
        isLoading
          ? (
            <>
              <Shimmer/>
              <Shimmer/>
              <Shimmer/>
              <Shimmer/>
              <Shimmer/>
              <Shimmer/>
              <Shimmer />
            </>
          )
          : (
            <>
              <FilterWrapper>
                {
                  statusOptions.map((status) => (
                    <div key={ status.name }>
                      <CheckBox 
                        checked={ checked[status.name] }
                        handleClick = { (e) => {
                          setChecked((prevValue) => ({
                            ...prevValue,
                            [status.name]: !checked[status.name]
                          }))
                          if(e.target.checked) {
                            filter.affiliate_payout_status_id = [...filter.affiliate_payout_status_id, status.id]
                            setFilter({ ...filter })
                          }
                          else {
                            const updatedFilter = filter.affiliate_payout_status_id.filter((checkedStatusId) => checkedStatusId !== status.id)
                            filter.affiliate_payout_status_id = updatedFilter
                            setFilter({ ...filter })
                          }
                        } }
                      />
                      <span>{ status.name }</span>
                    </div>
                  ))
                }
              </FilterWrapper>
              <PayoutLisCard />
            </>
          )
      }
    </Wrapper>
  )
}

export default PayoutsView
